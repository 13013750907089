import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-attendeeform',
  templateUrl: './attendeeform.component.html',
  styleUrls: ['./attendeeform.component.css']
})
export class AttendeeformComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
